import "./WalkingPhrase.css";

export const WalkingPhrase = () => {
  return (
    <div className="walking-box-fixed">
      <div className="walking-box-moving">
        <p className="walking-content">
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>{" "}
          New Website is coming soon. Reach us out for work inquiries and
          commissions. Email us at{" "}
          <a className="email-link" href="mailto:hello@whywedesign.co">
            hello@whywedesign.co
          </a>
        </p>
      </div>
    </div>
  );
};
