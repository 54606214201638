import { useEffect, useState, useRef } from "react";
import "./Video.css";

type videoList = Blob[]

export const Video = ({ videoQueue }) => {
  const [videoList, setVideoList] = useState<videoList>([]);
  const videoRef = useRef(null)
  const videoIndex = useRef(0)

  const preLoadVideo =  async  () => {
    if (!videoQueue[0] || videoQueue.length === videoList.length || !Array.isArray(videoList))return
    const videoResp =  await fetch(videoQueue[videoList.length])
    const videoBlob =  await videoResp.blob()
    setVideoList([...videoList, videoBlob])
  }


  const playFromVideoList = () => {
    const idx = videoIndex.current
    const url = URL.createObjectURL(videoList[idx])
    const vid = videoRef.current
    vid.src = url
    vid.load();

    if(videoIndex.current === videoQueue.length - 1) {
      videoIndex.current = 0
    } else {
      videoIndex.current = idx + 1
    }
    
  }

  const onEnded = () => {
    if(videoList.length > 0) playFromVideoList()
  };
    

  useEffect(() => {
    if(videoList.length === 0) return
    preLoadVideo()
    if(videoList.length === 1) playFromVideoList()
  }, [videoList]);

  useEffect(() => {
    preLoadVideo()
  },[videoQueue])

  // 

  return (
    <div className="video-container">
      <video
        className="video"
        muted
        autoPlay
        playsInline
        ref={videoRef} 
        onEnded={onEnded}             
        width={"100%"}
      />
    </div>
  );
};
