import "./Description.css";

export const Description = () => (
  <div className="description-box">
    <p className="description-title">We are WHY WE DESIGN</p>
    <p className="description-title">Strategy, Creative and</p>
    <p className="description-title">Sustainable Design Studio.</p>
    <p className="description-title">Powered by Women!</p>
  </div>
);
