import "./Local.css";
export const Local = () => {
  return (
    <div className="local-box">
      <div className="arrow-box">
        <img src="/images/seta_WWD.svg" alt="arrow" />
      </div>
      <div className="local-content">
        <p className="local-item">São Paulo</p>
        <p className="local-item">London</p>
        <p className="local-item">Centroamerica</p>
      </div>
    </div>
  );
};
