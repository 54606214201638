import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Home from "screens/Home/Home";

export default function MainRoutes() {
  let location = useLocation();

  const screens = [
    {
      path: "/",
      screen: <Home />,
      title: "WWD | Coming soon",
    },
  ];

  useEffect(() => {
    let newTitle = screens.find(
      (screen) => screen.path === location.pathname
    )?.title;

    document.title = newTitle || "Why We Design";
  }, [location]);

  return (
    <Routes>
      {screens.map((screen) => (
        <Route path={screen.path} key={screen.path} element={screen.screen} />
      ))}
    </Routes>
  );
}
