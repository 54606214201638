import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./fonts/Aquawax/Aquawax-Pro-Bold-trial.ttf";
import "./fonts/Aquawax/Aquawax-Pro-Regular-trial.ttf";
import "./fonts/Aquawax/Aquawax-Pro-Medium-trial.ttf";
// import "./fonts/Aquawax/Aquawax-Pro-Heavy-trial.ttf.";
// import "./fonts/Aquawax/Aquawax-Pro-Variable-trial.ttf.";
// import "./fonts/Aquawax/Aquawax-Pro-Ultrabold-trial.ttf.";
// import "./fonts/Aquawax/Aquawax-Pro-Light-trial.ttf.";
// import "./fonts/Aquawax/Aquawax-Pro-Thin-trial.ttf.";
// import "./fonts/Aquawax/Aquawax-Pro-Extralight-trial.ttf.";
// import "./fonts/Aquawax/Aquawax-Pro-Demibold-trial.ttf.";

// Context
import Context from "./context/context";

ReactDOM.render(
  <React.StrictMode>
    <Context>
      <App />
    </Context>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
